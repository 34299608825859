var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("SearchForm", {
        on: { search: _vm.fetchData },
        model: {
          value: _vm.queryParams,
          callback: function ($$v) {
            _vm.queryParams = $$v
          },
          expression: "queryParams",
        },
      }),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["zt.add_zt"],
              expression: "['zt.add_zt']",
            },
          ],
          staticStyle: { "margin-bottom": "10px" },
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: {
            click: function ($event) {
              return _vm.$refs.edit.handleEdit()
            },
          },
        },
        [_vm._v("添加")]
      ),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.body",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                  modifiers: { body: true },
                },
              ],
              attrs: {
                data: _vm.tableData,
                "element-loading-text": "加载中 ...",
                border: "",
                "header-align": "center",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "专题ID",
                  "min-width": "80px",
                  prop: "activity_id",
                  align: "center",
                  "header-align": "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "专题名称",
                  "min-width": "200",
                  prop: "name",
                  align: "center",
                  "header-align": "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "活动时间",
                  "min-width": "310",
                  align: "center",
                  formatter: _vm.timeFormatter,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否有效",
                  "min-width": "80px",
                  prop: "is_active",
                  align: "center",
                  "header-align": "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.is_active
                          ? _c("span", { staticClass: "warn" }, [
                              _vm._v("有效"),
                            ])
                          : _c("span", { staticClass: "info" }, [
                              _vm._v("无效"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "链接",
                  "min-width": "300",
                  prop: "link",
                  "header-align": "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          { attrs: { href: scope.row.link, target: "_blank" } },
                          [_vm._v(_vm._s(scope.row.link))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "类型",
                  "min-width": "80px",
                  prop: "platform",
                  align: "center",
                  "header-align": "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  "min-width": "80px",
                  prop: "creator.name",
                  align: "center",
                  "header-align": "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "300px",
                  align: "center",
                  "header-align": "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["zt.design_zt"],
                                expression: "['zt.design_zt']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.goEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("页面配置")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["zt.modify_zt"],
                                expression: "['zt.modify_zt']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.edit.handleEdit(scope.row.uuid)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["zt.shelf_zt"],
                                expression: "['zt.shelf_zt']",
                              },
                            ],
                            staticStyle: { margin: "0 10px" },
                            attrs: {
                              title: `确定要${
                                scope.row.is_display ? "下架" : "上架"
                              }该专题页吗？`,
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.handleDisplay(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "small",
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.is_display ? "下架" : "上架"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["zt.log_zt"],
                                expression: "['zt.log_zt']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.operationLog.showLogs(
                                  scope.row.uuid
                                )
                              },
                            },
                          },
                          [_vm._v("日志")]
                        ),
                        scope.row.lucky_draw
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["zt.view_ldd"],
                                    expression: "['zt.view_ldd']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.prizeStatistical.handleEdit(
                                      scope.row.lucky_draw_uuid
                                    )
                                  },
                                },
                              },
                              [_vm._v("数据查询")]
                            )
                          : _vm._e(),
                        scope.row.hasLink
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goH5Page(scope.row)
                                  },
                                },
                              },
                              [_vm._v("渠道配置")]
                            )
                          : _vm._e(),
                        !scope.row.is_display
                          ? _c(
                              "el-popconfirm",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["zt.delete_zt"],
                                    expression: "['zt.delete_zt']",
                                  },
                                ],
                                staticStyle: { margin: "0 10px" },
                                attrs: { title: `确定要删除该专题页吗？` },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "small",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("pagination", {
                attrs: {
                  total: _vm.count,
                  page: _vm.queryParams.page,
                  limit: _vm.queryParams.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "size", $event)
                  },
                  pagination: _vm.fetchData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("edit", { ref: "edit" }),
      _c("operation-log", { ref: "operationLog" }),
      _c("PrizeStatistical", { ref: "prizeStatistical" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }