var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-card", { staticClass: "box-card" }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [
        _c(
          "span",
          [
            _vm._v("参与人数："),
            _c("el-tag", { attrs: { type: "warning" } }, [
              _vm._v(_vm._s(_vm.pNumber)),
            ]),
          ],
          1
        ),
        _vm._v("    "),
        _c(
          "span",
          [
            _vm._v("抽奖次数："),
            _c("el-tag", { attrs: { type: "warning" } }, [
              _vm._v(_vm._s(_vm.drawNumber)),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "cus-table" },
      [
        _c("div", { staticClass: "table_cell" }, [
          _c("div", { staticClass: "cell bg-grey" }, [_vm._v("名称")]),
          _c("div", { staticClass: "cell" }, [_vm._v("已抽中奖次数")]),
          _c("div", { staticClass: "cell" }, [_vm._v("剩余中奖次数")]),
        ]),
        _c("div", { staticClass: "table_cell" }, [
          _c("div", { staticClass: "cell bg-grey" }, [_vm._v("总计")]),
          _c("div", { staticClass: "cell" }, [
            _vm._v(_vm._s(_vm.winPrizeTotal)),
          ]),
          _c("div", { staticClass: "cell" }, [
            _vm._v(_vm._s(_vm.surplusPrizeTotal)),
          ]),
        ]),
        _vm._l(_vm.prizeData, function (item, index) {
          return _c("div", { key: index, staticClass: "table_cell" }, [
            _c("div", { staticClass: "cell bg-grey" }, [
              _vm._v(_vm._s(item.prize_name)),
            ]),
            _c("div", { staticClass: "cell" }, [
              _vm._v(_vm._s(item.win_number)),
            ]),
            _c("div", { staticClass: "cell" }, [
              _vm._v(_vm._s(item.surplus_number)),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }