var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "抽奖数据统计",
        visible: _vm.dialogVisible,
        width: "80%",
        top: "2vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-descriptions",
        { staticClass: "margin-top", attrs: { column: 3, border: "" } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { "label-class-name": "w150 f14" } },
            [
              _c("template", { slot: "label" }, [_vm._v(" 抽奖ID ")]),
              _vm._v(" " + _vm._s(_vm.prizeInfo.config_id) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            { attrs: { "label-class-name": "w150 f14" } },
            [
              _c("template", { slot: "label" }, [_vm._v(" 抽奖名称 ")]),
              _vm._v(" " + _vm._s(_vm.prizeInfo.name) + " "),
            ],
            2
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c("PrizeNumber", {
        attrs: {
          "p-number": _vm.prizeInfo.pnumber,
          "draw-number": _vm.prizeInfo.draw_number,
          "prize-data": _vm.prizeInfo.prize_data,
        },
      }),
      _c("el-divider"),
      _vm.dialogVisible
        ? _c("UserData", { attrs: { uuid: _vm.uuid } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }