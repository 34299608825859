var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "专题页基本信息", visible: _vm.dialogVisible },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "dataForm", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "专题名称",
                prop: "name",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "360px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入专题名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "专题类型",
                "label-width": _vm.formLabelWidth,
                required: "",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.platform,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "platform", $$v)
                    },
                    expression: "form.platform",
                  },
                },
                [_c("el-radio", { attrs: { label: "H5" } }, [_vm._v("H5")])],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否包含抽奖",
                "label-width": _vm.formLabelWidth,
                required: "",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disabledPrize },
                  model: {
                    value: _vm.form.lucky_draw,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "lucky_draw", $$v)
                    },
                    expression: "form.lucky_draw",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("是")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否配置渠道链接",
                "label-width": _vm.formLabelWidth,
                required: "",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disabledPrize },
                  model: {
                    value: _vm.form.hasLink,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "hasLink", $$v)
                    },
                    expression: "form.hasLink",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("是")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.lucky_draw
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "抽奖配置",
                    prop: "lucky_draw_uuid",
                    "label-width": _vm.formLabelWidth,
                    required: "",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item w400",
                      attrs: {
                        clearable: "",
                        placeholder: "请选择抽奖配置",
                        disabled: _vm.disabledPrize,
                      },
                      model: {
                        value: _vm.form.lucky_draw_uuid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "lucky_draw_uuid", $$v)
                        },
                        expression: "form.lucky_draw_uuid",
                      },
                    },
                    _vm._l(_vm.freshPrize, function (item) {
                      return _c("el-option", {
                        key: item.uuid,
                        attrs: { label: item.name, value: item.uuid },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "起止时间",
                prop: "time_range",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "上线日期",
                  "end-placeholder": "下线日期",
                  "default-time": _vm.defaultTime,
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "picker-options": _vm.pickerOptions,
                },
                model: {
                  value: _vm.form.time_range,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "time_range", $$v)
                  },
                  expression: "form.time_range",
                },
              }),
              _c("div", { staticClass: "f-grey f13 lh16 mt10" }, [
                _vm._v(
                  " 备注：为确保专题模板发布成功，请设置合理的下线时间；专题页过期之后会自动下线，用户无法访问，如需重新上线，请修改时间后重新发布。 "
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "活动备注",
                prop: "memo",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "360px" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入活动备注" },
                    model: {
                      value: _vm.form.memo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "memo", $$v)
                      },
                      expression: "form.memo",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }