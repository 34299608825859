var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "活动操作日志", visible: _vm.dialogVisible },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.body",
              value: _vm.tableLoading,
              expression: "tableLoading",
              modifiers: { body: true },
            },
          ],
          attrs: {
            data: _vm.tableData,
            "element-loading-text": "加载中 ...",
            border: "",
            "header-align": "center",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "操作类型",
              width: "200px",
              prop: "data_type",
              align: "center",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.logType[scope.row.data_type])),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "修改时间",
              width: "250px",
              prop: "create_time",
              align: "center",
              "header-align": "center",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作人",
              prop: "creator.name",
              align: "center",
              "header-align": "center",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            "text-align": "center",
            "margin-top": "20px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关 闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }